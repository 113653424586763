<template>
  <form class="w-64 mx-auto grid gap-4" @submit.prevent="submit">
    <FormAlert :type="alertType" :message="alertMessage" />
    <FormInput
      id="form_user_resend_activation_mail"
      :modelValue="email"
      type="email"
      required
      placeholder="Email"
      @update:modelValue="$emit('update:email', $event)"
    />

    <input
      type="submit"
      class="cursor-pointer py-2 px-4 text-white article-label !font-medium"
      :value="$t('ResendActivationMail')"
      :class="
        isMm
          ? 'rounded-md bg-black transform translate-y-px'
          : 'rounded-full bg-blue'
      "
    />
  </form>
</template>

<script setup lang="ts">
import { FetchError } from 'ofetch'

const { isMm } = useBrand()
const props = defineProps<{
  email: string
}>()

const nuxtApp = useNuxtApp()

async function submit() {
  try {
    await nuxtApp.$api.user.resendActivationMail(props.email)
    setAlert('EmailSent', 'success')
  } catch (error) {
    if (error instanceof FetchError && error.response) {
      setAlert(error.response?._data)
    } else {
      console.error('Error in resendActivationMail:', error)
    }
  }
}

// Error handling
const { setAlert, alertType, alertMessage } = useAlert()
</script>
