<template>
  <div v-if="error">
    <i18n-t
      keypath="trialSignup.errors.couldNotAddPaperTrial"
      tag="div"
      scope="global"
    >
      <a :href="config.public.site.supportEmail">{{
        config.public.site.supportEmail
      }}</a>
    </i18n-t>
  </div>
  <CommonLoading v-else />
</template>

<script setup lang="ts">
import type { Subscription, Paper } from '~/typesAuto/apicore/v1'
import type { ContentPaper } from '~/typesManual/content_api/paper'

const props = defineProps<{
  papers: ContentPaper[]
}>()

const nuxtApp = useNuxtApp()
const indexStore = useIndexStore()
const userStore = useUserStore()
const config = useRuntimeConfig()
const dataLayer = useDataLayer()
const cosmos = useCosmos()
const route = useRoute()

const emit = defineEmits(['in-waiting-period'])

const newSubscriptions = ref<Subscription[]>([])
const error = ref(false)

onMounted(() => {
  grantAccess()
})

async function grantAccess() {
  if (!userStore.user || !userStore.user.clientId) {
    return
  }

  const paperCalls: Promise<Paper>[] = []
  for (let i = 0; i < props.papers.length; i++) {
    const paper = props.papers[i]
    paperCalls.push(
      nuxtApp.$api.user.paper(paper.RecordId, userStore.user.userId)
    )
  }
  const userPapers = await Promise.all(paperCalls)
  // Check if the user is trying to gain access during waiting period
  if (userPapers.find((p) => p.waitingPeriod)) {
    emit('in-waiting-period')
    dataLayer.trial.trialOver()
    return
  }

  try {
    const createSubscriptionCalls: Promise<Subscription>[] = []
    for (let i = 0; i < props.papers.length; i++) {
      const paper = props.papers[i]
      createSubscriptionCalls.push(
        nuxtApp.$api.subscription.createSubscription(userStore.user.clientId, {
          subscriptionParameters: {
            paper: paper.RecordId,
            isTrial: 1,
            maxAllowedClientEmails: 1,
            subscriptionLength: 1,
            aOpen: 1,
            signupSourceId: 2,
          },
          subscriptionEmailsdto: [
            {
              email: userStore.user.email,
              name: userStore.user.name,
              recieveMail: 1,
              textMail: 1,
            },
          ],
        })
      )
    }
    newSubscriptions.value = await Promise.all(createSubscriptionCalls)

    if (!newSubscriptions.value.length) {
      error.value = true
      return
    }

    await cosmos.signupCompletion({
      userId: userStore.user.userId.toString(),
      clientId: userStore.user.clientId.toString(),
      route,
      sessionId: indexStore.sessionId,
      ctx: {
        userGuid: userStore.user?.userGuid,
        newUser: false,
        newClient: false,
        newSubscriptions: newSubscriptions.value.map((subscription) => ({
          subscriptionId: subscription.id,
          createTime: subscription.createTime,
          paperId: subscription.product?.paperId ?? undefined,
        })),
      },
    })
    const paperIds = props.papers.map((paper) => paper.RecordId).join(',')
    await nuxtApp.$api.user.sendAccessGrantedReceiptEmail(
      userStore.user.userId,
      paperIds
    )
    await userStore.loadUserFromAutologin({
      autologin: userStore.user.legacyAutoToken,
    })
  } catch (err) {
    // TODO: Sentry
    console.error(err)
    error.value = true
  }
}
</script>
