<template>
  <div class="bg-black bg-opacity-5 py-8 md:pb-12 pt-16">
    <div class="container">
      <div class="w-[296px] h-[36px] md:mb-3">
        <img src="/MM_Logo.svg" alt="Mandag Morgen logo" />
      </div>

      <div
        class="flex flex-col md:grid md:grid-rows-2 md:grid-cols-2 justify-between w-full"
      >
        <div class="mb-10 md:mb-0 w-1/2 row-start-1">
          <p class="list-title-xxs mb-4 md:mb-2">Læs mindre - forstå mere</p>
          <p class="body-s mb-3 md:mb-6 hidden md:flex">
            MandagMorgen er en unik kombination af mediehus og tænketank, som
            hjælper beslutningstagere med at forstå de udfordringer, vores
            samfund står over for, og med at udvikle og formidle nye løsninger.
          </p>
          <NuxtLink
            :to="{
              name: 'article',
              params: {
                id: 'her-er-mandag-morgens-loefter-til-laeserne',
              },
            }"
          >
            <CommonButton
              bg="bg-black bg-opacity-10"
              class="font-bold"
              border="border-none"
              text="text-black"
            >
              7 løfter til dig som læser
            </CommonButton>
          </NuxtLink>
        </div>

        <ul
          class="flex flex-col md:flex-wrap md:col-start-2 max-w-fit row-start-1 justify-self-end mb-4 md:mb-0"
        >
          <li
            v-for="(item, index) in staff"
            :key="index"
            class="flex flex-col md:basis-[calc(40%-20px)] md:mr-10"
          >
            <span class="list-title-4xs">{{ item.title }}</span>
            <span class="body-s">{{ item.name }}</span>
          </li>
        </ul>

        <div
          class="col-span-2 row-start-2 border-t border-black border-opacity-10 pt-4 md:pt-8 flex flex-col md:flex-row gap-4 justify-between"
        >
          <div class="flex flex-col col-start-1 row-start-2">
            <span class="mb-1 list-title-4xs">Adresse</span>
            <span class="mb-1 body-s">Ny Kongensgade 10</span>
            <span class="mb-1 body-s">1472 København K</span>
            <span class="mb-1 body-s"
              >Tlf. <a href="tel:+4533939323">33 93 93 23</a></span
            >
            <a class="mb-2 body-s" href="mailto:mm@mm.dk">mm@mm.dk</a>

            <span class="body-s">CVR nr.: 38253395</span>
            <p class="mt-1 body-xs w-1/2">
              Mandag Morgen leveres af Mandag Morgen ApS, der ejes af Alrow
              Media ApS.
            </p>
          </div>
          <ul
            v-if="indexStore.menu"
            class="flex flex-col md:grid md:grid-cols-4 gap-6 md:gap-8 col-start-2"
          >
            <li v-for="(item, index) in indexStore.menu.footer" :key="index">
              <div class="mb-1 list-title-4xs">
                {{ item.headline }}
              </div>
              <ul>
                <li
                  v-for="(subitem, subItemIndex) in item.submenu"
                  :key="'footersubmenu' + subItemIndex"
                >
                  <NuxtLink
                    v-if="subitem.link.indexOf('https') == -1"
                    :to="subitem.link"
                    class="mb-1 !font-normal inline-block body-s"
                  >
                    {{ subitem.headline }}
                  </NuxtLink>
                  <a
                    v-else
                    :href="subitem.link"
                    class="mb-1 !font-normal inline-block body-s"
                  >
                    {{ subitem.headline }}
                  </a>
                </li>
              </ul>
            </li>
          </ul>
        </div>
      </div>

      <div class="opacity-50 pt-6 text-xs flex justify-between">
        Copyright © {{ config.public.site.name }},
        {{ new Date().getFullYear() }}
        <a href="http://alrow.media/">
          <NuxtImg src="/alrow.svg" width="100" class="mt-2" />
        </a>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
const indexStore = useIndexStore()
const config = useRuntimeConfig()

const staff = [
  {
    title: 'Chefredaktør',
    name: 'Andreas Baumann',
  },
  {
    title: 'Adm Direktør',
    name: 'Anne Marie Kindberg',
  },
  {
    title: 'CFO',
    name: 'Anders Jørning',
  },
  {
    title: 'Ansv. Chefredaktør',
    name: 'Jakob Nielsen',
  },
  {
    title: 'Kommerciel direktør',
    name: 'Michael Thomsen',
  },
  {
    title: 'Formand og udgiver',
    name: 'Rasmus Nielsen',
  },
]
</script>
