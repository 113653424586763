<template>
  <div
    class="flex items-center w-full mt-2 mb-8 px-4 py-2 rounded-full border border-gray-300"
  >
    <IconSearch
      class="w-5 h-5 transition-all duration-200 fill-current hover:text-blue cursor-pointer"
      @click="onSearch"
    />
    <input
      ref="search"
      v-model="searchWords"
      type="text"
      class="article-label outline-none bg-white transition-all duration-300 py-2 ml-2"
      :placeholder="placeholder"
      @keyup.enter="onSearch"
    />
  </div>
</template>

<script setup lang="ts">
import IconSearch from '~/assets/icons/search.svg?component'
const route = useRoute()
const indexStore = useIndexStore()
const nuxtApp = useNuxtApp()
const searchWords = ref('')
const search = ref<HTMLInputElement>()
const { isMm } = useBrand()

defineProps<{
  placeholder: string
}>()
// Methods
const onSearch = () => {
  // If we're on a paper, search on the paper path. Otherwise search from root.
  const searchUrl =
    indexStore.currentPaper?.Url && !isMm
      ? `/${indexStore.currentPaper.Url}/${nuxtApp.$t('Routes.Search')}/${
          searchWords.value
        }`
      : `/${nuxtApp.$t('Routes.Search')}/${searchWords.value}`
  navigateTo({
    path: searchUrl,
  })
}

// Watchers
watch(
  () => route.path,
  () => {
    searchWords.value = ''
  }
)
</script>
