<template>
  <ul
    class="px-5 text-center relative border-y border-gray-300 pointer-events-auto transform transition-all duration-300 mt-0 w-full"
  >
    <li
      v-for="(paper, i) in sortedPapers"
      :key="i"
      class="py-3 mx-10 inline-block"
      :class="{
        'border-b-2 border-blue ': indexStore.currentPaper?.Url === paper.Url,
      }"
      :style="{
        borderColor:
          indexStore.currentPaper?.Url === paper.Url
            ? paper.ColorRGB
            : undefined,
      }"
    >
      <NuxtLink
        ref="paperLinks"
        class="font-mm-serif uppercase text-[12px] md:text-[16px]"
        :to="{ name: 'paper', params: { paper: paper.Url } }"
        >{{ paper.Name }}
      </NuxtLink>
    </li>
  </ul>
</template>

<script setup lang="ts">
const indexStore = useIndexStore()
const desiredOrder = [7, 10, 9, 8]
const sortedPapers = computed(() => {
  return [...indexStore.activePapers].sort((a, b) => {
    return desiredOrder.indexOf(a.RecordId) - desiredOrder.indexOf(b.RecordId)
  })
})
</script>
