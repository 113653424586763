<template>
  <div v-if="notificationsStore.shouldShow">
    <LayoutModal @modal-close="notificationsStore.clear()">
      <div class="text-center">
        <img
          :src="config.public.site.logo"
          alt="logo"
          class="h-9 mb-8 ml-auto mr-auto"
        />
        <div v-for="group in notificationsStore.grouped" :key="group[0]">
          <h1 class="headline-xs !font-normal">
            {{ $t('notifications.types.' + group[0]) }}
          </h1>
          <div class="pb-4">
            <div
              v-for="(item, key) in group[1]"
              :key="group[0] + key"
              class="p-1"
            >
              <span v-html="$t(item.translationKey)"></span>
            </div>
          </div>
        </div>
        <div v-if="notificationsStore.shouldShowSubscribeButton">
          <template v-if="config.public.site.identifier === 'altinget_no'">
            <CommonButton
              class="w-full mb-2"
              bg="bg-blue"
              bg-hover="hover:bg-denimblue"
              text="text-white"
              text-hover="text-white"
              border="border-none"
              size="xlarge"
              @click="subscribe"
              >{{ $t('subscribeToLovebakken') }}</CommonButton
            >
          </template>
          <template v-else-if="config.public.site.identifier === 'altinget_se'">
            <NuxtLink
              :to="{
                name: 'account_subscription',
              }"
            >
              <CommonButton
                class="w-full mb-2"
                bg="bg-blue"
                bg-hover="hover:bg-denimblue"
                text="text-white"
                text-hover="text-white"
                border="border-none"
                size="xlarge"
                @click="notificationsStore.clear()"
                >Gå till Mitt Altinget</CommonButton
              >
            </NuxtLink>
          </template>
          <CommonButton
            size="xlarge"
            class="w-full"
            @click="notificationsStore.clear()"
          >
            {{ $t('noThankYouMaybeLater') }}
          </CommonButton>
        </div>
        <CommonButton
          v-else
          size="xlarge"
          class="w-full"
          @click="notificationsStore.clear()"
        >
          {{ $t('okLowercaseK') }}
        </CommonButton>
      </div>
    </LayoutModal>
  </div>
</template>

<script setup lang="ts">
const config = useRuntimeConfig()
const nuxtApp = useNuxtApp()
const userStore = useUserStore()
const notificationsStore = useNotificationsStore()

const LOVEBAKKEN_PAPER_ID = 5

async function subscribe() {
  if (!userStore.user?.email) {
    return
  }

  await nuxtApp.$api.user.subscribeNewsletter(
    userStore.user?.email,
    LOVEBAKKEN_PAPER_ID,
    true
  )
  notificationsStore.clear()
}
</script>
